class favoritiesListComponent extends Component {

    static name(){
        return "favoritiesListComponent";
    }

    static componentName() {
        return 'favoritiesListComponent';
    }
    data() {
        return {
            viewmode: 'module-small',
            itemsFlag:false,
            orderSelected:"",
            favoritiesList : []
        }
    }
    getWatch() {
        return {
            '$store.state.appState':'fetchData',
            '$store.state.itemTimeFlag':'fetchData',
            '$store.state.favorites.length':'fetchData',
            '$store.state.orderBy':'fetchData',
            '$store.state.orderDirection':'fetchData'
        };
    }
    getComputed(){
        return {
            initItem:function(){
                return this.$store.getters.getItemPerPage*this.$store.getters.getOffset;
            },
            lastItem:function(){
                return this.$store.getters.getItemPerPage*this.$store.getters.getOffset + this.$store.getters.getItemPerPage;
            },
            moduleObject: function () {
                return {
                    'green-text': this.viewmode=='module'
                }
            },
            listObject: function () {
                return {
                    'green-text': this.viewmode=='list'
                }
            },
            itemClassObject: function () {
                return {
                    'oo-item-container-list':this.viewmode=='list',
                    'oo-item-container':this.viewmode=='module' || this.viewmode=='module-small',
                    'oo-item-container-small': this.viewmode=='module-small',
                    'oo-item-container-normal': this.viewmode=='module',
                    'col-12': this.viewmode=='list',
                    'col-6 col-sm-6 col-lg-3 px-1 px-lg-2': this.viewmode=='module',
                    'col-6 col-sm-4 col-lg-2 px-1 px-lg-2': this.viewmode=='module-small'
                }
            }
        };
    }

    getMethods(){
        return {
            fetchData:this.fetchData,
            getItemByCode:this.getItemByCode,
            openItemView:this.openItemView,
            reorderItems: this.reorderItems
        };
    }

    fetchData(){
        let self =this;
        let newfavoritiesList= [];
        let itemWithOutPrices = [];
        for(let itemCode of this.$store.getters.getFavoritesItems) {
            let itemLoad = this.$store.getters.getItemByCode(itemCode);
            if(itemLoad){
                if (itemLoad && !itemLoad.isCalculate || itemLoad.Price==0) {
                    itemWithOutPrices.push(itemLoad);
                }
                newfavoritiesList.push(itemLoad)
            }

        }
        if(itemWithOutPrices.length>0)
            this.$store.dispatch('updatePrices', itemWithOutPrices).then(()=>{
                self.favoritiesList = __lodash__.orderBy(newfavoritiesList, self.$store.getters.getOrderBy,this.$store.getters.getOrderDirection);
            });
        else
            this.favoritiesList = __lodash__.orderBy(newfavoritiesList, this.$store.getters.getOrderBy,this.$store.getters.getOrderDirection);
    }

    getItemByCode(itemCode){
        return this.$store.getters.getItemByCode(itemCode);
    }

    openItemView(item){
        if(item)
            this.$router.push(`/view/${item.Code}`);
    }

    reorderItems () {
        this.$store.dispatch('reorderItems',this.orderSelected);
        this.favoritiesList = __lodash__.orderBy(this.favoritiesList, this.$store.getters.getOrderBy,this.$store.getters.getOrderDirection);
    }

    mounted(){
        return async function() {
            this.fetchData();
        }
    }

    getTemplate() {
        return `<div class="row">
                   <div class="col-12">
                        <h5 class="title">{{tr('Favorite List')}} ({{favoritiesList.length}})</h5>
                    </div>
                    <div class="col-12 mb-4">
                        <div class="row justify-content-between align-items-center">
                            <div class="col-6 col-sm-7 mb-2 mb-sm-0">
                               <div class="form-inline">
                                    <label class="mr-1">{{tr('Order by')}}:</label>
                                    <select id="soflow" v-model="orderSelected" class="form-control custom-select" @change="reorderItems">
                                        <option disabled value="">{{tr('Select One')}}</option>
                                        <template v-for="orderField of $store.getters.getSetting.ItemOrdered">
                                            <option :value="orderField.fields.internalId" :selected="orderField==orderSelected">{{tr(orderField.fields.ItemField)}}({{orderField.fields.OrderDirection}})</option>
                                        </template>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6 col-sm-5 ">
                                <div class="row justify-content-end view-mode pr-2">
                                    <div class="mr-2 d-none d-sm-block">
                                        <span @click="viewmode='module-small'" :class="{'active':viewmode=='module-small'}">
                                            <i class="fas fa-th fa-2x"></i>
                                        </span>
                                    </div>
                                    <div class="mr-2">
                                        <span @click="viewmode='module'" :class="{'active':viewmode=='module'}">
                                            <i class="fas fa-th-large fa-2x"></i>
                                        </span>
                                    </div>

                                    <div class="mr-2">
                                        <span @click="viewmode='list'" :class="{'active':viewmode=='list'}">
                                            <i class="fas fa-th-list fa-2x"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row" >
                            <template v-for="item in favoritiesList">
                                <div  :class="itemClassObject" >
                                  <template v-if="viewmode=='list'">
                                      <itemViewListComponent :key="item.Code" :item="item" @click="openItemView(item)"></itemViewListComponent>
                                  </template>
                                  <template v-else>
                                      <itemViewModuleComponent :key="item.Code" :item="item" @click="openItemView(item)"></itemViewModuleComponent>
                                  </template>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="col-12" v-if="favoritiesList.length>0">
                        <div class="row justify-content-center">
                            <paginationComponent currentView="favorites" :items="favoritiesList"></paginationComponent>
                        </div>
                    </div>
                </div>`;
    }
}

favoritiesListComponent.registerComponent();
